'use client';

import { FlagsmithProvider, useFlags } from 'flagsmith/react';
import React from 'react';

import { createContext } from '../createContext';
import { communitiesFlag, init } from './communitiesFlag';

export type CommunitiesFlagContext = typeof communitiesFlag;

export const [CommunitiesFlagsContextProvider, useCommunitiesFlagContext] =
  createContext<CommunitiesFlagContext>({
    name: 'CommunitiesFlagsProvider',
    hookName: 'useCommunitiesFlag',
    defaultValue: communitiesFlag,
  });

export function CommunitiesFlagProvider({ children }: { children: React.ReactNode }) {
  return (
    <FlagsmithProvider flagsmith={communitiesFlag} serverState={communitiesFlag.getState()}>
      <>{children}</>
    </FlagsmithProvider>
  );
}

export function useCommunitiesFlags<T extends string[]>(key: T) {
  return useFlags(key);
}
