import { cva } from 'class-variance-authority';
const root = cva([
    'flex gap-toast-container bg-tertiary p-toast rounded-toast border border-toast box-border w-toast-container shadow-toast min-h-0',
], {
    variants: {
        orientation: {
            vertical: 'flex-col',
            horizontal: 'items-center',
        },
    },
    defaultVariants: {
        orientation: 'vertical',
    },
});
const text = {
    title: 'text-toast-default-title toast-title',
    description: 'text-toast-default-description toast-description line-clamp-2',
};
const icon = cva('h-icon-toast w-icon-toast', {
    variants: {
        variant: {
            default: 'icon-toast',
            error: 'icon-toast-error ',
            success: 'icon-toast-success',
            warning: 'icon-toast-warning',
        },
    },
    defaultVariants: {
        variant: 'default',
    },
});
export const styles = {
    root,
    text,
    icon,
};
