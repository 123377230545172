'use client';

import Base from 'react-countdown';
import clsx from 'clsx';

import { Badge } from '@zealy/design-system';

import { useDidMount } from '#hooks/useDidMount';

export const Countdown = ({
  date,
  label,
  className,
}: {
  date: string | Date;
  label?: string;
  className?: string;
}) => {
  const didMount = useDidMount();
  if (!didMount) return null;
  return (
    <div className={clsx('flex flex-col items-start', className)}>
      {!!label && <Badge size="xs">{label}</Badge>}
      <Base
        date={new Date(date)}
        zeroPadDays={2}
        renderer={({ formatted }) => {
          return (
            <span className="text-primary flex flex-row body-paragraph-xl-bold gap-150">
              <span className="flex flex-row gap-25 items-baseline">
                {formatted.days}
                <span className="text-secondary body-paragraph-sm-bold">d</span>
              </span>
              <span className="flex flex-row gap-25 items-baseline">
                {formatted.hours}
                <span className="text-secondary body-paragraph-sm-bold">h</span>
              </span>
              <span className="flex flex-row gap-25 items-baseline">
                {formatted.minutes}
                <span className="text-secondary body-paragraph-sm-bold">m</span>
              </span>
              <span className="flex flex-row gap-25 items-baseline">
                {formatted.seconds}
                <span className="text-secondary body-paragraph-sm-bold">s</span>
              </span>
            </span>
          );
        }}
      />
    </div>
  );
};
