import type { DateTimeFormatOptions } from 'next-intl';
import dayjs from 'dayjs';
import { useFormatter, useTranslations } from 'next-intl';
import Image from 'next/image';
import Link from 'next/link';

import { Avatar, Badge, Link as DesignSystemLink, Tag } from '@zealy/design-system';
import { CalendarEventLine, TimerLine, UserLine } from '@zealy/icons';

import { Countdown } from '#components/Countdown';
import { getSprintStatus, SprintStatus } from '#utils/getSprintStatus';
import { cn } from '#utils/utils';

import type { SprintHeaderProps } from './SprintHeader.types';

const DATETIME_OPTIONS: DateTimeFormatOptions = {
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  hour12: true,
};

export const SprintHeader = ({
  style,
  className,
  sprintId,
  sprintImgSrc,
  communityName,
  communityAvatarImgSrc,
  communitySubdomain,
  title,
  startDate,
  endDate,
  participants,
  ...restProps
}: SprintHeaderProps) => {
  const startDateIsValid = !!startDate && dayjs(startDate).isValid();
  const endDateIsValid = !!endDate && dayjs(endDate).isValid();
  const sprintStatus =
    startDateIsValid && endDateIsValid
      ? getSprintStatus({
          startingAt: startDate,
          endingAt: endDate,
        })
      : null;

  const format = useFormatter();
  const t = useTranslations('sprints');
  const communityNameLabel = communityName ? communityName : t('placeholders.unknown-community');
  const trimmedTitle = title ? title.trim() : undefined;
  const titleLabel = trimmedTitle
    ? trimmedTitle
    : t('placeholders.sprint-title', { communityName });

  return (
    <div
      data-testid={restProps['data-testid']}
      style={style}
      className={cn('flex flex-wrap md:flex-nowrap items-center gap-300', className)}
    >
      {sprintImgSrc ? (
        <Image
          className="self-center object-cover rounded-xl aspect-square flex-shrink-0"
          alt=""
          src={sprintImgSrc}
          width={164}
          height={164}
        />
      ) : null}
      <div className="self-start flex flex-col justify-between gap-200 min-w-0">
        <div className="flex flex-col gap-100">
          <span className="flex gap-100 items-center body-md text-secondary">
            {communityName ?? communityAvatarImgSrc ? (
              <Avatar size="xxs" name={communityName} src={communityAvatarImgSrc} />
            ) : null}
            {communityName && communitySubdomain ? (
              <DesignSystemLink
                as={Link}
                className="inline-block truncate w-full"
                href={`/cw/${communitySubdomain}`}
              >
                {t('by-community', { communityName: communityNameLabel })}
              </DesignSystemLink>
            ) : null}
          </span>
          <span className="headings-headline-h1 text-primary truncate">
            {communitySubdomain && sprintId ? (
              <Link href={`/cw/${communitySubdomain}/leaderboard/${sprintId}`}>{titleLabel}</Link>
            ) : (
              titleLabel
            )}
          </span>
          <div className="flex flex-wrap gap-100">
            {startDateIsValid && endDateIsValid ? (
              <Tag leftIcon={<CalendarEventLine />} size="xs" variant="filled">
                {format.dateTimeRange(startDate, endDate, DATETIME_OPTIONS)}
              </Tag>
            ) : null}
            {participants !== null && participants !== undefined ? (
              <Tag leftIcon={<UserLine />} size="xs" variant="filled">
                {t('participants', {
                  count: participants,
                })}
              </Tag>
            ) : null}
          </div>
        </div>
        {startDateIsValid && endDateIsValid ? (
          sprintStatus !== SprintStatus.Completed ? (
            <Countdown
              date={sprintStatus === SprintStatus.Ongoing ? endDate : startDate}
              label={sprintStatus === SprintStatus.Ongoing ? t('ending-in') : t('starting-in')}
            />
          ) : (
            <Badge size="sm" icon={<TimerLine />}>
              {t('sprint-ended')}
            </Badge>
          )
        ) : null}
      </div>
    </div>
  );
};
