import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import clsx from 'clsx';
import { AlertOctagonLine, AlertTriangleLine, CheckCircleLine, CrossLine, InfoLine, } from '@zealy/icons';
import { cn } from '../../utils/cn';
import { Button } from '../Button';
import { styles } from './Toast.styles';
const ICONS = {
    default: _jsx(InfoLine, {}),
    success: _jsx(CheckCircleLine, {}),
    error: _jsx(AlertOctagonLine, {}),
    warning: _jsx(AlertTriangleLine, {}),
};
export const Toast = ({ title, description, action, icon, variant = 'default', onDismiss, orientation, }) => {
    const dir = orientation ?? description ? 'vertical' : 'horizontal';
    return (_jsxs("div", { className: styles.root({
            orientation: dir,
        }), children: [_jsxs("div", { className: "flex gap-toast-container flex-1", children: [React.cloneElement(icon ?? ICONS[variant ?? 'default'], {
                        className: cn(icon?.props.className, styles.icon({ variant })),
                    }), _jsxs("div", { className: clsx('flex flex-col gap-toast-container flex-1 w-full', {
                            'justify-center': !description && dir === 'horizontal',
                        }), children: [_jsx("p", { className: styles.text.title, children: title }), description && _jsx("p", { className: styles.text.description, children: description })] }), onDismiss && (_jsx(Button, { variant: "ghost", onClick: onDismiss, onlyIcon: true, leftIcon: _jsx(CrossLine, {}), size: "xs", mutedText: true, "aria-label": "Dismiss" }))] }), action && (_jsx(Button, { ...action, variant: "muted", size: "sm", className: cn('self-end', action.className) }))] }));
};
